import React, { useState, useEffect } from "react";

interface TypewriterProps {
  textArray: string[];
  typingDelay?: number;
  erasingDelay?: number;
  newTextDelay?: number;
}

const Typewriter: React.FC<TypewriterProps> = ({
  textArray,
  typingDelay = 1000,
  erasingDelay = 600,
  newTextDelay = 2000,
}) => {
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(textArray[0].length);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    const advanceText = () => {
      timerId = setTimeout(
        () => {
          if (typing) {
            setCharIndex((prev) => prev + 1);
          } else {
            setCharIndex((prev) => prev - 1);
          }
        },
        typing ? typingDelay : erasingDelay
      );
    };

    if (typing && charIndex === textArray[textIndex].length + 1) {
      setTyping(false);
      timerId = setTimeout(advanceText, newTextDelay);
    } else if (!typing && charIndex === 0) {
      setTyping(true);
      setTextIndex((prevIndex) => {
        // If at the end of the array, reset to the second item, skipping the first one
        return (prevIndex + 1) % textArray.length === 0
          ? 1
          : (prevIndex + 1) % textArray.length;
      });
    } else {
      advanceText();
    }

    return () => clearTimeout(timerId);
  }, [
    textIndex,
    charIndex,
    typing,
    textArray,
    typingDelay,
    erasingDelay,
    newTextDelay,
  ]);

  return (
    <div className="container flex-1 flex justify-end">
      <p className="text-3xl font-bold text-gray-300">
        <span className="text-stone-700">
          {textIndex === 0
            ? textArray[0]
            : textArray[textIndex].substring(0, charIndex)}
        </span>
        {textIndex === 0 ? null : (
          <span
            className={`inline-block bg-beige ml-1 w-1 h-6 ${
              typing ? "animate-blink" : ""
            }`}
          ></span>
        )}
      </p>
    </div>
  );
};

export default Typewriter;
